@use '../includes' as *;

// Grid layout below is inspired by the following
// use of minmax() to set a min width for each
// column, then evenly distribute the remaining space
// https://gridbyexample.com/examples/example28/

$min-width: $g80;

.show_grid {
  display: grid;
  grid-template-columns: repeat(3, minmax($min-width, 1fr));
  gap: var(--gutter);

  &:has(+ .show_grid_link_button) {
    margin-block-end: $g8;
  }

  @include breakpoint($sm) {
    grid-template-columns: repeat(6, minmax($min-width, 1fr));
  }

  @include breakpoint($lg) {
    &:has(+ .show_grid_link_button) {
      margin-block-end: $g24;
    }
  }
}

.show_grid_wrapper {
  margin-block-end: var(--spacing-block);
  padding-inline: var(--spacing-inline);
}

.show_grid_link_button {
  display: flex;
  justify-content: center;
  width: 100%;
}
